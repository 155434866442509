<template>
    <div>
        <!-- Breadcrumb start -->
   <section class="bradcrumb_sec">
    <div class="container-fluid custome_container">
       <div class="row justify-content-center">
          <div class="col-md-11">
             <div class="bread_crumb_box mb_22">
                <h2 class="heading_2 font_bold mb_23">Setup <span class="text_primary">guidelines</span></h2>
                <nav aria-label="breadcrumb">
                   <ol class="breadcrumb">
                      <li class="breadcrumb-item"><router-link class="navbar-brand m-0 p-0" :to="{ name: 'user_home' }"> Home </router-link></li>
                      <li class="breadcrumb-item active" aria-current="page">Setup Guidelines</li>
                   </ol>
                </nav>
             </div>
          </div>
       </div>
    </div>
 </section>
 <!-- Breadcrumb end -->
  <section :class="!show_self_assessment_block ? 'work_from_office setup_guidline how_else_ergo mb-0' : 'work_from_office setup_guidline how_else_ergo'">
      <!-- <img src="../assets/images/work-from-office/first-section.png" alt="img" class="first_section"> -->
      <div class="container">
          <div class="row justify-content-center">
              <div class="col-md-12 col-xxl-12">
                <div class="row">
                    <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2 border-0">
                           <router-link :to="{ name: 'seating' }">
                           <img src="../assets/images/setup_guidline/setup_guidline5.png" alt="img" class="mb_66">
                           <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal1">
                              <img src="../assets/images/home-essentials/sseating.png" alt="img" class="mb_66">
                           </a> -->
                            <div class="card2_text_box">
                               <h5 class="font_bold text-center font_size_26 mb_20 text_black">Seating</h5>
                               <p class="text-center font_size_18 mb_20 text_black">
                                Ergo has your back (literally)  no matter where you sit. 
                               </p>
                               <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                            </div>
                          </router-link>
                            
                         </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2 border-0">
                           <router-link :to="{ name: 'desk' }">
                            <img src="../assets/images/setup_guidline/setup_guidline4.png" alt="img" class="mb_66">
                            <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                              <img src="../assets/images/home-essentials/ddesk.png" alt="img" class="mb_66">
                           </a> -->
                            <div class="card2_text_box">
                               <h5 class="font_bold text-center font_size_26 mb_20 text_black">Desk</h5>
                               <p class="text-center font_size_18 mb_20 text_black">
                                 Learn to set yourself up well at any desk you use.  
                               </p>
                               <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                            </div>
                           </router-link>
                         </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2 border-0">
                           <router-link :to="{ name: 'desktop_items' }">
                            <img src="../assets/images/setup_guidline/setup_guidline3.png" alt="img" class="mb_66">
                            
                            <div class="card2_text_box">
                               <h5 class="font_bold text-center font_size_26 mb_20 text_black">Desktop items</h5>
                               <p class="text-center font_size_18 mb_20 text_black">
                                How and what you place on your desk impacts your work style. 
                               </p>
                               <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                            </div>
                           </router-link>
                         </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2 border-0">
                           <router-link :to="{ name: 'screens' }">
                            <img src="../assets/images/setup_guidline/setup_guidline2.png" alt="img" class="mb_66">
                            <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal3">
                              <img src="../assets/images/home-essentials/screens.png" alt="img" class="mb_66">
                           </a> -->
                            <div class="card2_text_box">
                               <h5 class="font_bold text-center font_size_26 mb_20 text_black">Screens</h5>
                               <p class="text-center font_size_18 mb_20 text_black">
                                Wanna have great posture and be productive?  Learn how. 
                               </p>
                               <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                            </div>
                           </router-link>
                         </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2 border-0">
                          <router-link :to="{ name: 'accessories' }">
                            <img src="../assets/images/setup_guidline/setup_guidline1.png" alt="img" class="mb_66">                           
                            <div class="card2_text_box">
                               <h5 class="font_bold text-center font_size_26 mb_20 text_black">Accessories</h5>
                               <p class="text-center font_size_18 mb_20 text_black">
                                It’s all in the details.  Learn how accessories can help. 

                               </p>
                               <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                            </div>
                          </router-link>
                         </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2 border-0">
                           <router-link :to="{ name: 'lighting' }">
                            <img src="../assets/images/setup_guidline/setup_guidline6.png" alt="img" class="mb_66">
                            <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal4">
                              <img src="../assets/images/home-essentials/lightning.png" alt="img" class="mb_66">
                           </a> -->
                            <div class="card2_text_box">
                               <h5 class="font_bold text-center font_size_26 mb_20 text_black">Lighting</h5>
                               <p class="text-center font_size_18 mb_20 text_black">
                                Learn how to use light to improve your output and avoid eyestrain. 
                               </p>
                               <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                            </div>
                           </router-link>
                         </div>
                    </div>
                </div>  
              </div>
          </div>  
                           
      </div>
  </section>
  <section class="take_an_ergonomic_parttwo mb_44" v-show="show_self_assessment_block">
    <div class="container">          
      <div class="row justify-content-center">
        <div class="col-md-12 col-xxl-10">
          <div class="ergonomic-image pt_26 pb_26  ">
             <div class="overlay">
                <div class="row">
              <div class="col-md-12 col-lg-7 col-xl-6">
                <div class="left-section pr_60 pl_60">                      
                    <h6 class="sub_heading_1 font_bold mb_22 text-white">Need more help?</h6>
                    <p class="text-white p_20 mb_54">Take a self-assessment today and see how ergo can help you feel better and work smarter. </p>
                    <div class="mt_14">
                      <router-link  :to="{ name: 'self_assessment_signup' }" class="btn btn_secondary font_size_16">Take a Self-Assessment</router-link>
                    </div>
                </div>
              </div>
            </div>
             </div>
            
          </div>              
        </div>
      </div>
    </div>
  </section>
   <!--<div data-bs-backdrop="static" data-bs-keyboav-show="selfassessmentpermission"rd="false" class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">

            <div class="video_elements">
              <a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"><img
                  src="../assets/images/close_modal.png"></a>
                  <iframe src="https://player.vimeo.com/video/140750648?h=e9628fe2de&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Sitting Pretty"></iframe>
             </div>
          </div>

        </div>
      </div>
    </div>
   <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">

            <div class="video_elements">
              <a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"><img
                  src="../assets/images/close_modal.png"></a>
              <iframe src="https://player.vimeo.com/video/661821993?h=0fcd3b22a4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Setting Up Your Work Area"></iframe>
            </div>
          </div>

        </div>
      </div>
    </div>
   <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">

            <div class="video_elements">
              <a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"><img
                  src="../assets/images/close_modal.png"></a>
              <iframe src="https://player.vimeo.com/video/586046799?h=cc4a2ef978&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Monitor Madness"></iframe>
            </div>
          </div>

        </div>
      </div>
    </div>
   <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">

            <div class="video_elements">
              <a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"><img
                  src="../assets/images/close_modal.png"></a>
              <iframe src="https://player.vimeo.com/video/661822350?h=43ed49e57b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="How to Set Up Your Lighting"></iframe>
            </div>
          </div>

        </div>
      </div>
    </div> -->
    </div>
</template>

<script>
export default {
  data () {
    return {
      show_self_assessment_block: true,
    }
  },
    name:"Setup_guidline",
    mounted(){
      document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;  
      let data = this.$store.getters["auth/authUser"];
         let company_data = data.client;
         if(company_data.site_menu_details.TakeASelfAssessment.status == 0){
            this.show_self_assessment_block = false;
         }
    },
}
</script>